.receipt-image {
  width: 100px;
  height: auto;
  border-radius: 4px;
}

.action-button {
  margin: 0;
}

.invoice-table {
  margin-top: 20px;
}

.receipt-preview {
  cursor: pointer;
}

.receipt-thumbnail {
  width: 100px;
  height: auto;
  border-radius: 4px;
  object-fit: cover;
}

.receipt-full-image {
  width: 100%;
  height: auto;
}


/* Custom styles for the receipt preview */
.preview-button {
  font-size: 16px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.receipt-modal {
  max-width: 50%;
  margin: auto;
}

.receipt-preview-body {
  text-align: center;
  padding: 20px;
}

.receipt-image {
  max-width: 90%;
  max-height: 50vh;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

.pdf-viewer {
  width: 60%;
  height: 70vh;
}

.csv-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.csv-download-link {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.csv-download-link:hover {
  background-color: #0056b3;
}