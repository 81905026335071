:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --background-color: #f8f9fa;
  --item-background: #ffffff;
  --border-color: #e0e0e0;
  --text-color: #333333;
  --hover-color: #ecf0f1;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: var(--text-color);
}

.title {
  text-align: center;
  color: var(--secondary-color);
  font-size: 32px;
  margin-bottom: 40px;
  font-weight: 300;
  letter-spacing: 1px;
}

.workArea {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.availableSteps,
.processSteps {
  flex: 1;
  background-color: var(--background-color);
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.sectionTitle {
  color: var(--secondary-color);
  font-size: 18px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 3px solid var(--primary-color);
  font-weight: 500;
}

.stepList {
  min-height: 400px;
  background-color: var(--item-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
  transition: all 0.3s ease;
}

.stepList:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.draggableItem {
  background-color: var(--item-background);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 15px 20px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  cursor: move;
  font-size: 16px;
}

.draggableItem:hover {
  background-color: var(--hover-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.draggableItem i {
  margin-right: 10px;
  color: var(--primary-color);
}

.removeButton {
  background-color: transparent;
  color: #e74c3c;
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  opacity: 0.7;
}

.removeButton:hover {
  opacity: 1;
  transform: scale(1.1);
}

.saveButton {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 20px;
  width: 100%;
}

.saveButton:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 992px) {
  .workArea {
    flex-direction: column;
  }

  .availableSteps,
  .processSteps {
    width: 100%;
    margin-bottom: 30px;
  }
}