.topbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #704f4f; /* Adjust as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.app-logo {
  width: 120px; /* Adjust size */
  height: auto;
}

.app-header-right {
  display: flex;
  align-items: center;
}

.header-dots {
  margin-right: 15px;
}

.icon-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-custom-color {
  background-color: #007bff; /* Adjust to match your design */
  border: none;
  color: #fff;
}

.btn-shine {
  /* Add any additional button effects like gradients or shadows */
}

.dropdown-menu-header {
  background-color: #007bff; /* Adjust to match your design */
}

.widget-heading {
  font-weight: bold;
  font-size: 16px; /* Adjust size */
}

.widget-subheading {
  color: #6c757d; /* Adjust color */
}
