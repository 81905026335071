.order-details-card {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.order-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.order-header > div {
    text-align: center;
}

.order-tabs {
    display: flex;
    margin-bottom: 20px;
}

.order-tabs button {
    padding: 10px 20px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
}

.order-tabs button.active {
    background-color: #fff;
    border-bottom: 2px solid #000;
}

.order-products {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.order-products th, .order-products td {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.order-summary {
    display: flex;
    justify-content: space-between;
}

.customer-details, .order-total {
    width: 48%;
}

.additional-fee {
    color: #ff0000;
}

.product-image {
    height: 60px;
    width: 100px;
    border-radius: 100%;
}

.icon-style {
    height: 45px;
}
.card-title {
    font-size: 20px !important;
}