.logout-modal {
    background: transparent !important; /* Remove the modal's background */
  }
  
  .modal-dialog {
    background: transparent !important; /* Ensure the dialog wrapper has no background */
  }
  
  .modal-content {
    background-color: #ffffff !important; /* Keep the white box inside */
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important; /* Darken background outside modal */
  }
  