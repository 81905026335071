.menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
}
.menu-icon {
  margin-right: 10px;
  font-size: 18px;
}
.submenu-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-left: 20px;
}
.submenu-icon {
  margin-right: 8px;
  font-size: 16px;
}
