// @font-face {
//     font-family: 'BoschSans-Regular':
//     src: local('BoschSans-Regular'),
//         url('./BoschSans-Regular-v5_003.ttf') format('ttf')
// }

@font-face {
    font-family: 'BoschSans-Regular';
    font-style: normal;
    font-weight: normal;
    src: url('./BoschSans-Regular-v5_003.ttf');
}
// @font-face {
//     font-family: 'CustomFont2Name':
//     src: local('CustomFont2Name'),
//          url('./fonts/CustomFont2Name.woff') format('woff'),
//          url('./fonts/CustomFont2Name.woff2') format('woff2');
// }