.unverified-icon-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    animation: zoom-blink 1.5s infinite;
  }
  
  .tooltip {
    visibility: hidden;
    background-color: #ff4d4d;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 10px;
    width: 200px;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    white-space: nowrap;
  }
  
  .unverified-icon-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  @keyframes zoom-blink {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  