/* Overlay styling to ensure clarity */
.swal2-container {
  z-index: 9999 !important; /* Ensures modal is on top */
}

/* Error message modal customization */
.swal2-popup {
  font-family: 'Arial', sans-serif;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
}

.swal2-title {
  color: #333; /* Dark title text */
  font-size: 1.5rem;
}

.swal2-content {
  color: #555;
  font-size: 1.1rem;
}

.swal2-icon {
  color: #d9534f; /* Red for error */
}

.swal2-confirm {
  background-color: #C1B314 !important; /* Customize confirm button */
  color: #fff;
  font-weight: bold;
  border-radius: 8px;
  font-size: 1rem;
  padding: 10px 20px;
}
